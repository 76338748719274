import { BrandConfig, BrandData, Colors, Constants, Content } from "../../types";

const brandData: BrandData = {
  id: 6,
  title: "Foxy's Fine Fries",
};

const colors: Colors = {
  lightMain: "rgba(246, 188, 27, 0.25)",
  main: "#F1D347",
  white: "#fff",
  darkGrey: "#1d262f",
  primaryButton: {
    main: "#A02605",
    hover: "#8D2104",
    color: "white",
  },
  primaryContainedButton: {
    main: "white",
    hover: "#E14A4A",
    color: "rgba(29, 38, 47, 1)",
    colorHover: "white",
    borderColor: "#A02605",
  },
  secondaryButton: {
    main: "#F1D347",
    hover: "#D7BC3E",
    color: "rgba(29, 38, 47, 1)",
  },
};

const content: Content = {
  aboutUs: {
    subtitle: "",
    text: "The secret to serving truly fine fries and tasty, tantalizing tots - you start with quality potatoes and fry to order each and every time. \nAt Foxy’s, we serve everything with a little extra sizzle. Our fries are always a perfect crispy, golden brown and our tots are always crunchy, salty perfection. You can enjoy them as a solo act, but they really are best served fully loaded as an ensemble. \nChoose your favorite from our Bacon Ranch Tots stacked with sliced hickory smoked chicken, smoked bacon, cheese sauce, and ranch dressing to our Fantastic Moo Fries staring chopped brisket, pulled pork, BBQ sauce, cheese sauce and tangy cole slaw stacked on top. We also offer truly off the hook sides - tangy coleslaw, smoky barbecue beans, and legit mac & cheese. Whatever your choice, our fries and tots will blow your mind.",
    title: "Foxy’s Fine Fries & Tots",
  },
  hero: {
    title: "Foxy’s Fine Fries & Tots",
    subtitle: "Truly fine fine fries and tots",
    orderNowText: "Order Now",
    viewMenuText: "View Menu",
  },
  meta: {
    title: "Foxy's Fine Fries",
    description:
      "Welcome to a world where potatoes meet perfection. Our fries are more than just a side; they're a golden, crispy indulgence waiting to elevate your every meal. Each bite is a celebration of crunchiness, cooked to golden perfection, and seasoned to savory excellence. From classic to unique flavors, our fries promise an unforgettable experience that'll keep you coming back for more. Join us on a flavorful journey, and let your taste buds revel in fry heaven",
    image: "",
    url: "",
  },
};

const constants: Constants = {
  menuAtAGlancePdf: "brand_Menu_At_Glance.pdf",
  privacyPolicyPdf: "Foxy_Privacy_policy.pdf",
  termsAndConditionsPdf: "Foxy_T&C.pdf",
};

const brandConfig: BrandConfig = {
  brandData,
  constants,
  content,
  colors,
};

export default brandConfig;
